import { track as trackEvent } from '../../global/analytics'
import eventConstants from '../../global/eventConstants'
import React, { Component } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import PromoImage from "../../../static/images/promos/promos-29-05.png";


const track = (eventName, eventProps) => {
  trackEvent(eventName, {
    ...eventProps,
    Page: eventConstants.privacy.pageName
  })
}

const ImageContainer = styled.div`
    //display: flex;
    //justify-content: center;
    //align-items: center;
    padding: 20px 15px;
    position:relative;
    background: white;

    :after {
        content:"";
        position:absolute;
        width:110%;
        bottom:0;
        left: 0;
        z-index:-1;
        //transform:scale(.9);
        box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.05)
    }

`
const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    padding: 25px 0;
    font-size: 28px;
    font-weight: bold;
`

const MainText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    font-size: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    
    p{
        text-align: center;
    }
`

class Promo2905 extends Component {
  componentDidMount () {
    track(eventConstants.terms.pageLoad)
  }

  render () {
    return (
      <div>
        <ImageContainer>
          <img src={PromoImage} alt="Promo Image" />
        </ImageContainer>
        <Title>
          <div style={{ opacity: 0.5 }}>What's New:</div>
          <div>Unroll.Me Widget</div>
        </Title>
        <MainText>
          <p>
            Unroll.Me's newest feature introduces a convenient way to view your Rollup right from your home screen with our new widget!
          </p>
          <p>
            Simply touch and hold an empty area on your Home Screen, tap the '+' icon at the top, and select our widget from the list.
          </p>
        </MainText>
      </div>
    )
  }
}

export default injectIntl(Promo2905)

